@import '../../variables.scss';

a.forgot-password {
	display: block;
	margin-top: 5px;
	font-size: 12px;
	color: $brandColor;
}

.password-email {
	font-size: 12px;
	color: #999;
	padding: 10px 0;
}

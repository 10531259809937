@import '../../variables.scss';

.list-header {
	font-size: calc(36px + 1.2vw) !important;
	margin-top: 70px !important;
	line-height: 100px !important;
	@include titleStyle;
}

.list-empty {
	font-size: calc(18px + 0.6vw) !important;
	margin-top: 25vh !important;
	color: #fff !important;
	font-weight: 500 !important;
}

.img-empty {
	width: calc(36px + 0.8vw) !important;
	margin: 0 0 10px 10px;
}

.create-button {
	position: fixed;
	top: 104px;
	right: 50px;
	border-radius: 50% !important;
	height: calc(48px + 1vw);
	width: calc(48px + 1vw);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25) !important;

	&:active {
		transform: scale(0.95) !important;
	}

	i::before {
		font-size: calc(22px + 0.6vw) !important;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin: 0 !important;
	}
}

.ui.menu.filter-menu {
	padding: 0;
	margin-left: 40px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25) !important;

	.item {
		cursor: pointer;

		&::before {
			display: none !important;
		}

		&:hover:not(.active) {
			opacity: 0.7;
			background: rgba(104, 72, 201, 0.8);
		}
	}
}

.city-search .ui.search input {
	margin-left: 20px;
	width: 300px;
}

.city-search .results {
	margin-left: 20px !important;
}

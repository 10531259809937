@import '../variables.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body,
h1,
.ui,
.content {
	font-family: 'Quicksand', sans-serif !important;
}

body ::-webkit-scrollbar {
	width: 5px;
}

.ui.popup {
	background: $brandColor;
	color: #fff;
	border: none;
	font-size: 12px;

	&::before {
		background: $brandColor !important;
		box-shadow: none !important;
	}
}

$sidemenuWidth: 180px;
$sidemenuHeigh: 60px;

.header-menu {
	width: $sidemenuWidth;
	height: $sidemenuHeigh;
	z-index: 111;
	position: fixed;
	background: rgb(59, 32, 117);
	border: 1px solid rgba(19, 18, 18, 0.2);
	box-shadow: 0 1px 2px 0 rgba(22, 22, 22, 0.2);
}

.logo-item {
	margin: 10px 12px;
	color: #fff;
}

.logo-img {
	height: 40px;
	width: 40.2px;
	margin: auto;
}

.logo-text {
	position: relative;
	display: inline;
	top: -15px;
	margin-left: 10px;
	font-size: 15px;
	font-weight: 500;
}

.content {
	font-weight: 500;
}

.privacy-item {
	padding: 10px 0 !important;

	.menu {
		margin: 0 !important;
	}
}

.privacy-text {
	font-size: 12px;
}

.text-right {
	text-align: right;
}

.side-menu {
	width: $sidemenuWidth !important;
	z-index: 110 !important;
	top: $sidemenuHeigh !important;
	padding-top: 8px;
	padding-bottom: $sidemenuHeigh;
}

.sidemenu-header {
	font-size: 15px !important;
	font-weight: 400 !important;
}

.sidemenu-item {
	font-size: 14px !important;

	&.active {
		font-weight: 500 !important;
	}
}

.content-pusher {
	margin-left: 180px;
	height: 100vh;
}

// .ui.top.attached.menu {
// 	border: none !important;
// }

.background-content {
	background-color: $backgroundColor;
}

.menu-header {
	background-color: $backgroundColor !important;
	height: $sidemenuHeigh;

	.item:before {
		display: none;
	}

	.dropdown {
		margin: 0 10px;
		i {
			color: #fff;
		}
	}

	.bars.icon {
		font-size: 23px;
		margin: 0 !important;
	}

	.menu-item {
		font-size: 15px;
		padding: 0 4px !important;

		.button {
			padding: 10px 21px !important;
			transform: scale(0.9);
			box-shadow: 1px 1px 2px #4c3299 !important;

			&:active {
				transform: scale(0.87) !important;
			}
		}
	}
}

.trophy-form {
	margin: 8px 5px !important;
}

.img-sidemenu {
	height: 20px;
	width: 20px;
	margin: 0 9px -4px 0;
}

.ui.dropdown .menu > .header {
	font-size: 13px !important;
}

.ui.menu .ui.dropdown .menu > .item {
	font-size: 15px !important;
}

.ui.form .field > label {
	color: #999;
	font-weight: 500;
}

.ui.modal > .header {
	background-color: $brandColor !important;
	color: #fff !important;
}

.search-container {
	padding-bottom: 0 !important;

	.input {
		width: 100%;
	}
}

.profile-avatar {
	background-color: #f6f4ff;
	border-radius: 50%;
	max-width: 150px;
}

.container-avatar {
	padding-bottom: 0 !important;
}

.container-shuffle {
	padding-top: 0 !important;
}

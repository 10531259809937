.bottom-content {
	padding: 10px !important;
}

.content {
	border: none !important;
}

.avatar-img {
	background-color: #f6f4ff !important;
	border-radius: 50%;
	transform: scale(1.4) !important;
	margin-left: 5px;
}

.user-text {
	margin-left: 15px;
	font-size: 15px;
	color: #fff;
}

.avatar-img:hover,
.user-text:hover {
	color: #fff;
}

.white-text {
	color: #fff;
}

.icon-trophy {
	font-size: 18px !important;
}

.edit-icon {
	margin-top: 4px !important;
	font-size: 16px !important;
	color: #fff;

	&:hover {
		opacity: 0.75;
	}
}

button.ui.icon.basic.vote-button,
button.ui.icon.basic.vote-button:focus,
button.ui.icon.basic.vote-button:hover {
	box-shadow: none !important;
	outline: none !important;
	background: none !important;
	margin: 0;
	padding: 12px 3px 0;
}

button.ui.icon.basic.vote-button i {
	font-size: 28px;
}

button.ui.icon.basic.vote-button:not(.green):not(.red) i {
	transform: scale(0.9);
	color: #aaa;
}

.vote-count {
	display: inline-block;
	width: 25px;
	text-align: center;
}

.star-icon {
	font-size: 22px;
	padding-top: 7px;
}

.blue-item {
	color: #254b87;
}

.list-card {
	margin: auto !important;
	min-width: 90%;
	border-radius: 17px !important;
}

.ui.card > :first-child {
	border-top-left-radius: 14px !important;
	border-top-right-radius: 14px !important;
}

.ui.card > :last-child {
	border-bottom-left-radius: 17px !important;
	border-bottom-right-radius: 17px !important;
}

.list-item {
	margin: 38px 0;
	font-size: 15px;
}

.text-item {
	margin-left: 10px;
	text-transform: capitalize;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

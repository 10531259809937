@import '../../variables.scss';
.home-header {
	text-align: center;
	font-size: 46px;
	line-height: 80px;
	margin: 60px 0;
}

.home-description {
	text-align: center;
	font-size: 32px;
	line-height: 60px;
	margin: 60px 0;
	@include titleStyle;
	color: #fff !important;
	text-transform: uppercase;
}

.home-text {
	color: #ffd428 !important;
}

.category-header {
	text-align: center;
	font-size: 30px;
	padding: 40px 0;
	@include titleStyle;
	color: #fff !important;
}

.icon-prize {
	height: 50px;
	margin: 0 10px -12px 0;
}
